import {
  App as CoreApp,
  IAppProps,
  IIdentityProviderProps as ICoreIdentityProviderProps,
} from '@reactionable/core';
import {
  IUIProviderProps,
  useUIProviderProps,
} from '@reactionable/ui-bootstrap';

import { GraphqlProvider } from '@reactionable/graphql/lib/Client';
import {
  IRouterProviderProps,
  useRouterProviderProps,
} from '@reactionable/router-dom';
import React, { lazy } from 'react';
import './i18n/i18n';
import './App.scss';
import { useIdentityProviderProps } from './identity/Identity';

const graphqlUri = process.env.REACT_APP_API_URL;

const HomeComponent = lazy(() => import('./views/home/Home'));
const NotFoundComponent = lazy(() => import('./views/not-found/NotFound'));
const DashboardComponent = lazy(() => import('./views/dashboard/Dashboard'));

const App: React.FC = () => {
  // Configure app
  const appConfig: IAppProps<
    ICoreIdentityProviderProps,
    IUIProviderProps,
    IRouterProviderProps
  > = {
    ui: useUIProviderProps(),
    router: useRouterProviderProps({
      HomeComponent,
      NotFoundComponent,
      routes: [
        {
          path: '/dashboard',
          component: DashboardComponent,
          privateRoute: true,
        },
      ],
    }),
    identity: (useIdentityProviderProps() as unknown) as ICoreIdentityProviderProps,
  };

  return <CoreApp {...appConfig} />;
};

const AppWrapper = () => {
  return (
    <GraphqlProvider uri={graphqlUri}>
      <App />
    </GraphqlProvider>
  );
};

export default AppWrapper;
