import { useTranslation } from '@reactionable/core/lib/i18n/I18n';
import {
  IIdentityProviderProps as ICoreIdentityProviderProps,
  IIdentityProviderValue as ICoreIdentityProviderValue,
  IUser as ICoreUser,
  useIdentityContext as coreUseIdentityContext,
  useIdentityProviderProps as coreUseIdentityProviderProps,
} from '@reactionable/core/lib/identity/Identity';
import { useMutation, useQuery } from '@reactionable/graphql';
import { useRouter } from '@reactionable/router-dom';
import { gql, useApolloClient } from '@apollo/client';

import {
  LoginInput,
  Mutation,
  MutationLoginArgs,
  Query,
  User,
} from '../graphql/graphql';

export type IUser = ICoreUser & User;

export type IIdentityProviderProps = ICoreIdentityProviderProps<IUser>;
export type IIdentityProviderValue = ICoreIdentityProviderValue<IUser>;

export type IUseIdentityProps = { redirectTo?: string };

const isBrowser = typeof window !== 'undefined';

const LocalStorageJwtKey = 'token';
export function setJWT(token: string): void {
  isBrowser && localStorage.setItem(LocalStorageJwtKey, token);
}
export function getJWT(): string | undefined {
  return isBrowser
    ? localStorage.getItem(LocalStorageJwtKey) || undefined
    : undefined;
}
export function unsetJWT(): void {
  isBrowser && localStorage.unsetItem(LocalStorageJwtKey);
}

export const userPayload = `
  id
  name
  email
`;

export function getUserRedirectionUrl(user?: IUser): string {
  if (!user) {
    return '/';
  }
  return '/dashboard';
}

const useSetUserWithRedirect = (): ((
  user: IUser | null | undefined
) => void) => {
  const { setUser } = useIdentityContext();
  const router = useRouter();

  return (user) => {
    setUser(user || null);
    if (user) {
      router.push(getUserRedirectionUrl(user));
    }
  };
};

export function useGetUser(): ReturnType<
  IIdentityProviderProps['useFetchUser']
> {
  const { data, error, ...result } = useQuery<Query['me']>(
    `query GetUser {
      me {
        ${userPayload}
      }
    }`,
    { fetchPolicy: 'network-only' }
  );

  let user: IUser | null | undefined =
    data !== undefined ? data ?? null : undefined;
  let realError = error;
  if (error?.message === 'Unauthorized') {
    user = null;
    realError = undefined;
  }

  return {
    ...result,
    error: realError,
    data: user,
  };
}

export function useLogin(): {
  login: (args: LoginInput) => Promise<IUser>;
} {
  const { t } = useTranslation();
  const client = useApolloClient();
  const login = async (input: LoginInput): Promise<IUser> => {
    try {
      const result = await client.mutate<
        { login: Mutation['login'] },
        MutationLoginArgs
      >({
        mutation: gql`mutation Login($input: LoginInput!) {
             login(input: $input) {
               user{
                 ${userPayload}
               }
               access_token
             }
           }`,
        variables: { input },
      });
      const user = result?.data?.login?.user ?? null;

      if (!user) {
        throw new Error('Undefined user');
      }

      if (result?.data?.login?.access_token) {
        setJWT(result.data.login?.access_token);
      }
      return user;
    } catch (error) {
      if (error?.graphQLErrors?.[0]?.extensions?.reason) {
        const errorReason = t(error.graphQLErrors[0].extensions.reason);
        throw new Error(errorReason);
      }
      throw error;
    }
  };

  return { login };
}

export function useLogout(): { logout: () => Promise<void> } {
  const { mutate } = useMutation<Mutation['logout']>(`mutation Logout {
      logout {
        status
      }
  }`);
  const logout = async () => {
    await mutate();
  };

  return { logout };
}

export function useIdentityProviderProps(): IIdentityProviderProps {
  const { logout } = useLogout();
  const { login } = useLogin();

  return coreUseIdentityProviderProps<IUser>({
    logout,
    login,
    useFetchUser: useGetUser,
    identityProvider: 'backend',
  });
}

export function useIdentityContext(): IIdentityProviderValue {
  return coreUseIdentityContext<IUser>();
}
