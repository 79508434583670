import { initializeI18n } from '@reactionable/core/lib/i18n/I18n';

import frCommon from './locales/fr/common.json';

const resources = {
  fr: {
    common: frCommon,
  },
};

initializeI18n({
  resources,
  lng: 'fr',
  fallbackLng: 'fr',
});
